import Formsy from 'formsy-react';
import React from 'react';
import { ITransmitter } from './../../../models/transmitter.model';
import CustomInput from './../form/custom-input.component';
import { InputWithLabel } from './../form/input-with-label.component';

interface ITransmitterFormProps extends IDispatchProps {
    className?: string;
    transmitter: ITransmitter;
    update(transmitter: ITransmitter): void;
    save(transmitter: ITransmitter): void;
}

export class TransmitterForm extends React.PureComponent<ITransmitterFormProps, {}> {
    render() {
        const { className, transmitter } = this.props;

        return (
            <div className={className || ''}>
                <Formsy noValidate onValidSubmit={data => this._handleSubmit(data)} method="post">
                    <CustomInput type="hidden" name="id" value={transmitter.id} />
                    <h2>Основные</h2>
                    <div className="row">
                        <InputWithLabel
                            className="col s12 input-field"
                            id="name"
                            type="text"
                            name="name"
                            title="Название"
                            value={transmitter.name}
                            required
                        />
                        <InputWithLabel
                            className="col s12 input-field"
                            id="address"
                            type="text"
                            name="address"
                            title="Адрес"
                            value={transmitter.address}
                        />
                        <InputWithLabel
                            className="col s6 input-field"
                            id="latitude"
                            type="text"
                            name="latitude"
                            title="Широта"
                            value={transmitter.latitude}
                        />
                        <InputWithLabel
                            className="col s6 input-field"
                            id="longitude"
                            type="text"
                            name="longitude"
                            title="Долгота"
                            value={transmitter.longitude}
                        />
                    </div>
                    <h2>Терем-4</h2>
                    <div className="row">
                        <InputWithLabel
                            className="col s12 input-field"
                            id="ch"
                            type="text"
                            name="ch"
                            title="Канал"
                            value={transmitter.ch}
                        />
                        <InputWithLabel
                            className="col s12 input-field"
                            id="period"
                            type="text"
                            name="period"
                            title="Период"
                            value={transmitter.period}
                        />
                    </div>
                    <h2>Интернет</h2>
                    <div className="row">
                        <InputWithLabel
                            className="col s12 input-field"
                            id="apn"
                            type="text"
                            name="apn"
                            title="apn"
                            value={transmitter.apn}
                        />
                    </div>
                    <h2>Почта</h2>
                    <div className="row">
                        <InputWithLabel
                            className="col s12 m4 input-field"
                            id="semail"
                            type="text"
                            name="semail"
                            title="semail"
                            value={transmitter.semail}
                        />
                        <InputWithLabel
                            className="col s12 m4 input-field"
                            id="remail1"
                            type="text"
                            name="remail1"
                            title="remail1"
                            value={transmitter.remail1}
                        />
                        <InputWithLabel
                            className="col s12 m4 input-field"
                            id="remail2"
                            type="text"
                            name="remail2"
                            title="remail2"
                            value={transmitter.remail2}
                        />
                        <InputWithLabel
                            className="col s12 input-field"
                            id="sub"
                            type="text"
                            name="sub"
                            title="sub"
                            value={transmitter.sub}
                        />
                        <InputWithLabel
                            className="col s5 input-field"
                            id="serv"
                            type="text"
                            name="serv"
                            title="serv"
                            value={transmitter.serv}
                        />
                        <InputWithLabel
                            className="col s5 input-field"
                            id="servip"
                            type="text"
                            name="servip"
                            title="servip"
                            value={transmitter.servip}
                        />
                        <InputWithLabel
                            className="col s2 input-field"
                            id="servport"
                            type="text"
                            name="servport"
                            title="servport"
                            value={transmitter.servport}
                        />
                    </div>
                    <h2>Телефон</h2>
                    <div className="row">
                        <InputWithLabel
                            className="col s12 m6 input-field"
                            id="ph1"
                            type="text"
                            name="ph1"
                            title="ph1"
                            value={transmitter.ph1}
                        />
                        <InputWithLabel
                            className="col s12 m6 input-field"
                            id="ph2"
                            type="text"
                            name="ph2"
                            title="ph2"
                            value={transmitter.ph2}
                        />
                    </div>
                    <div className="row">
                        <div className="col s12">
                            <button className="btn waves-effect waves-light btn-full-on-small" type="submit">
                                {transmitter.id ? 'Сохранить' : 'Обновить'}
                            </button>
                        </div>
                    </div>
                </Formsy>
            </div>
        );
    }

    private _handleSubmit(data: ITransmitter) {
        const { save, update } = this.props;

        if (!data.id || data.id === '0') {
            save(data);
        } else {
            update(data);
        }
    }
}
