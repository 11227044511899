import queryString from 'query-string';
import React from 'react';

interface IPagination {
    pageCount: number;
    itemCount: number;
    page: number;
    pathname?: string;
    search?: string;
    onChange?: (page: number) => void;
}

export class Pagination extends React.Component<IPagination, {}> {
    private _limit: number = 3;

    render() {
        const { pageCount, itemCount, page } = this.props;
        const currentPage = parseInt(page.toString(), 10);
        let prev = currentPage - this._limit;
        prev = prev < 1 ? 1 : prev;
        let next = currentPage + this._limit;
        next = next > pageCount ? pageCount : next;

        const pages = this._getArrayPages(this._limit, pageCount, currentPage);

        return (
            <div>
                {this._hasPreviousPages() || this._hasNextPages() ? (
                    <div className="navigation well-sm" id="pagination" />
                ) : null}
                <ul className="pagination">
                    {this._hasPreviousPages() ? (
                        <li className="waves-effect">
                            <a href={this._href(true)} onClick={event => this._onChange(event, prev)}>
                                <i className="material-icons">chevron_left</i>
                            </a>
                        </li>
                    ) : (
                        <li className="disabled">
                            <a>
                                <i className="material-icons">chevron_left</i>
                            </a>
                        </li>
                    )}
                    {pages && pages.length ? (
                        pages.map((p, index) => (
                            <li key={index} className={currentPage === p.number ? 'active' : ''}>
                                <a href={p.url} onClick={event => this._onChange(event, p.number)}>
                                    {p.number}
                                </a>
                            </li>
                        ))
                    ) : (
                        <li className="active">
                            <a>1</a>
                        </li>
                    )}
                    {this._hasNextPages() ? (
                        <li className="waves-effect">
                            <a href={this._href()} onClick={event => this._onChange(event, next)}>
                                <i className="material-icons">chevron_right</i>
                            </a>
                        </li>
                    ) : (
                        <li className="disabled">
                            <a>
                                <i className="material-icons">chevron_right</i>
                            </a>
                        </li>
                    )}
                    <li>Всего: {itemCount}</li>
                </ul>
            </div>
        );
    }

    private _isObject(value: any): boolean {
        const type = typeof value;
        return !!value && (type === 'object' || type === 'function');
    }

    private _href(prev?: any, params?: any): string {
        const { pathname, search } = this.props;

        let query = queryString.parse(search);

        if (typeof prev === 'object') {
            params = prev;
            prev = false;
        } else {
            prev = typeof prev === 'boolean' ? prev : false;
            query.page = parseInt(query.page, 10);
            query.page = prev ? (query.page -= 1) : (query.page += 1);
            query.page = query.page < 1 ? 1 : query.page;
        }

        // allow overriding querystring params
        // (useful for sorting and filtering)
        // another alias for `_.assign` is `_.extend`
        if (this._isObject(params)) {
            query = Object.assign(query, params);
        }

        return pathname + '?' + queryString.stringify(query);
    }

    private _hasNextPages(): boolean {
        const { pageCount, page } = this.props;
        if (typeof pageCount !== 'number' || pageCount < 0)
            throw new Error('pagination: `pageCount` is not a number >= 0');
        return page < pageCount;
    }

    private _hasPreviousPages(): boolean {
        return this.props.page > 1;
    }

    private _getArrayPages(limit: number, pageCount: number, currentPage: number): { number: number; url: string }[] {
        const pages = [];
        const maxPage = pageCount;

        // limit default is 3
        limit = limit || 3;

        if (typeof limit !== 'number' || limit < 0) throw new Error('pagination: `limit` is not a number >= 0');

        if (typeof pageCount !== 'number' || pageCount < 0)
            throw new Error('pagination: `pageCount` is not a number >= 0');

        if (typeof currentPage !== 'number' || currentPage < 0)
            throw new Error('pagination: `currentPage` is not a number >= 0');

        if (limit > 0) {
            const start = currentPage - limit > 0 ? currentPage - limit : 1;
            const end = currentPage + limit > maxPage ? maxPage : currentPage + limit;

            for (let i = start; i <= end; i++) {
                pages.push({
                    number: i,
                    url: this._href().replace('page=' + (currentPage + 1), 'page=' + i)
                });
            }
        }

        return pages;
    }

    private _onChange(event: React.MouseEvent<any>, page: number): void {
        if (typeof this.props.onChange === 'function') {
            event.preventDefault();
            this.props.onChange(page);
        }
    }
}
