import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from './../../redux/interfaces/IAppState.d';

interface IFooterOwnProps {}

interface IFooterStateProps {}

class Component extends React.PureComponent<IFooterStateProps & IDispatchProps & IFooterOwnProps, {}> {
    render() {
        return (
            <footer className="page-footer grey darken-4 no-padding hide-on-med-and-down" id="index-footer">
                <div className="footer-copyright">
                    <div className="container">
                        Разработано{' '}
                        <a
                            href="http://вебдизайн-в-перми.рф/"
                            target="_blank"
                            title="Перейти на сайт WebCmsDesign"
                            className="orange-text text-lighten-3"
                        >
                            WebCmsDesign
                        </a>
                        <div className="right">v{process.env.VERSION}</div>
                    </div>
                </div>
            </footer>
        );
    }
}

export const Footer = connect<IFooterStateProps, IDispatchProps, IFooterOwnProps>(
    (state: IAppState) => ({}),
    dispatch => ({ dispatch } as any)
)(Component);
