import classnames from 'classnames';
import React from 'react';

interface IModalComponentProps {
    className?: string;
    trigger?: JSX.Element;
}

export class ModalComponent extends React.PureComponent<IModalComponentProps, {}> {
    private _container: HTMLDivElement;
    private _instance: M.Modal;

    constructor(props: IModalComponentProps) {
        super(props);

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    }

    componentDidMount() {
        this._instance = new M.Modal(this._container);
    }

    componentWillUnmount() {
        this._instance.destroy();
    }

    render() {
        const { children, className, trigger } = this.props;
        return (
            <div className={classnames(className)}>
                {trigger && React.cloneElement(trigger, { onClick: this.open })}
                <div className="modal" ref={ref => (this._container = ref)}>
                    {children}
                </div>
            </div>
        );
    }

    open(): void {
        this._instance.open();
    }

    close(): void {
        this._instance.close();
    }
}
