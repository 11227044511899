import React from 'react';
import { Link } from 'react-router';

interface IMenuItemProps {
    item: { link: string; name?: string; icon?: string };
    className: string;
}

export class MenuItem extends React.PureComponent<IMenuItemProps, {}> {
    render() {
        const { item, className } = this.props;

        return (
            <li className={className}>
                <Link to={item.link} title={item.name}>
                    {item.name}
                    {item.icon && <i className="material-icons right">{item.icon}</i>}
                </Link>
            </li>
        );
    }
}
