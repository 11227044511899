import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { goBack } from 'react-router-redux';
import { IOwnProps } from './../../../interfaces/IOwnProps';
import { navigationUtils } from './../../utils/navigation.utils';
import { Footer } from './../footer/footer.component';
import { HeaderComponent } from './../header/header.component';

interface ILayoutProps extends IOwnProps {
    title?: string;
    className?: string;
}

class Component extends React.PureComponent<ILayoutProps & IDispatchProps, {}> {
    render() {
        const { className, location, title, routes } = this.props;
        const root = this.props.routes ? this.props.routes[0].path : '/';
        const defaultTitle = 'TmServer';
        const contentClass = `content ${className}`;
        const isParent = navigationUtils.isParent(routes);

        return (
            <div>
                <Helmet title={title} />
                <HeaderComponent
                    routes={this.props.routes}
                    root={root}
                    currentRoute={location.pathname}
                    title={title || defaultTitle}
                />
                <div className={contentClass}>
                    <div className="page-header">
                        {!isParent && (
                            <Link to="/#" className="back-link hide-on-med-and-down" onClick={this._goBack.bind(this)}>
                                <i className="material-icons">arrow_back</i>
                            </Link>
                        )}
                        {title && <h1 className="hide-on-med-and-down">{title}</h1>}
                    </div>
                    {this.props.children}
                </div>
                <Footer />
            </div>
        );
    }

    private _goBack(event: React.MouseEvent<HTMLAnchorElement>): void {
        event.preventDefault();
        const { dispatch } = this.props;
        dispatch(goBack());
    }
}

export const Layout = connect<{}, IDispatchProps, ILayoutProps>(() => ({}), dispatch => ({ dispatch } as any))(
    Component
);
