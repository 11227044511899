import React from 'react';
import ListItemWrapper from './list-item-wrapper.component';

export class List<T> extends React.Component<{ items: T[]; itemType: React.ReactType; section?: string }, {}> {
    render() {
        const { itemType } = this.props;
        const items: T[] = this.props.items || [];

        return (
            <ul className="collection">
                {items.map((item, key) => <ListItemWrapper key={key} item={item} type={itemType} {...this.props} />)}
            </ul>
        );
    }
}
