import moment from 'moment';
import React from 'react';
import { Link } from 'react-router';
import { INotification } from './../../../models/notification.model';
import { AvatarComponent } from './../avatar/avatar.component';
import { TooltipComponent } from './../tooltip/tooltip.component';

export class NotificationComponent extends React.PureComponent<{ className: string; item: INotification }, {}> {
    render() {
        const { className, item } = this.props;

        return (
            <li className={`${className} avatar avatar-minimum`}>
                <AvatarComponent value={item.title} />
                <span className="title">
                    {item.text}{' '}
                    <TooltipComponent tooltip={moment(item.createdAt).format('DD.MM.YYYY HH:mm:ss')}>
                        {moment.duration(new Date().getTime() - moment(item.createdAt).valueOf()).humanize()} назад
                    </TooltipComponent>
                </span>
                <Link to={item.link.slice(1)} className="secondary-content">
                    <i className="material-icons">more</i>
                </Link>
            </li>
        );
    }
}
