import { withFormsy } from 'formsy-react';
import React from 'react';

@withFormsy
export class InputWithLabel extends React.Component<any, any> {
    private _labelElm: HTMLLabelElement;

    componentDidUpdate() {
        if (this.props.getValue() && this._labelElm.className.indexOf('active') === -1) {
            this._labelElm.className += ' active';
        }
    }

    changeValue(event: React.FormEvent<any>) {
        this.props.setValue(event.currentTarget[this.props.type === 'checkbox' ? 'checked' : 'value']);
        if (typeof this.props.onChange === 'function') {
            const t = setTimeout(() => {
                clearTimeout(t);
                this.props.onChange(this.props.getValue());
            }, 0);
        }
    }

    render() {
        const { className, id, required, readOnly, icon, placeholder } = this.props;
        let inputClass;
        let errorMessage;

        if (this.props.isFormSubmitted()) {
            inputClass = this.props.showRequired() || this.props.showError() ? 'invalid' : 'valid';
            errorMessage = this.props.getErrorMessage();
        }

        if (required && this.props.showRequired()) {
            errorMessage = 'Обязательное поле';
        }

        let iconBlock;
        if (icon) {
            iconBlock = <i className="material-icons prefix">{icon}</i>;
        }

        return (
            <div className={className}>
                {iconBlock}
                <input
                    type={this.props.type || 'text'}
                    name={this.props.name}
                    id={id}
                    onChange={event => this.changeValue(event)}
                    value={this.props.getValue()}
                    checked={this.props.type === 'checkbox' && this.props.getValue() ? this.props.getValue() : null}
                    required={required}
                    className={inputClass}
                    readOnly={readOnly}
                    placeholder={placeholder}
                />
                <label htmlFor={id} data-error={errorMessage} ref={ref => (this._labelElm = ref)}>
                    {this.props.title}
                </label>
            </div>
        );
    }
}

// export const InputWithLabel = Formsy.withFormsy(InputWithLabelComponent);
