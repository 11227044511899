// tslint:disable:max-line-length
import queryString from 'query-string';
import React from 'react';
import { MatchLocationArgs } from 'react-router/lib/match';
import { IOwnProps } from './../../../interfaces/IOwnProps';
import { IPageFilter } from './../../../interfaces/IPageFilter';

export interface IListPage<T> {
    data: IQueryResult<T>;
    filter: IPageFilter;
}
export interface IListPageProps<T> extends IListPage<T> {}
export interface IListPageOwnProps extends IOwnProps {
    changeFilter(filter: IPageFilter): void;
    query(options: { params: IPageFilter }): void;
}

export class ListPage<T, P extends IListPageProps<T>> extends React.Component<
    P & IDispatchProps & IListPageOwnProps,
    {}
> {
    static getTitle(options?: MatchLocationArgs, obj?: any): string {
        return '';
    }

    filterModal: HTMLDivElement;
    loaded: boolean = false;

    getData(): void {
        throw new Error('Not implemented');
    }

    changePage(page: number): void {
        this.changeFilter({ page } as IPageFilter);
    }

    changeFilter(newFilter: IPageFilter): void {
        const filter = { ...(this.props.filter as IPageFilter), ...newFilter };
        window.history.pushState({}, '', `${location.pathname}?${queryString.stringify(filter)}`);
        this.props.changeFilter(filter);
        this.props.query({ params: filter });
    }

    onFilterSubmit(newFilter: IPageFilter) {
        const filter = { ...(this.props.filter as IPageFilter), ...newFilter, page: 1 }; // Сброс номера страницы
        window.history.pushState({}, '', `${location.pathname}?${queryString.stringify(filter)}`);
        this.props.changeFilter(filter);
        this.props.query({ params: filter });
    }
}
