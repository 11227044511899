import React from 'react';

interface IAvatarComponentProps {
    value: string;
}

export class AvatarComponent extends React.PureComponent<IAvatarComponentProps, {}> {
    private _container: HTMLCanvasElement;
    private _colors: string[] = [
        '#1abc9c',
        '#2ecc71',
        '#3498db',
        '#9b59b6',
        '#34495e',
        '#16a085',
        '#27ae60',
        '#2980b9',
        '#8e44ad',
        '#2c3e50',
        '#f1c40f',
        '#e67e22',
        '#e74c3c',
        '#95a5a6',
        '#f39c12',
        '#d35400',
        '#c0392b',
        '#bdc3c7',
        '#7f8c8d'
    ];

    componentDidMount() {
        this._update();
    }

    componentDidUpdate(nextProps: IAvatarComponentProps) {
        if (nextProps.value !== this.props.value) {
            this._update();
        }
    }

    render() {
        return <canvas className="circle" id="user-icon" width="42" height="42" ref={ref => (this._container = ref)} />;
    }

    private _update(): void {
        const { value } = this.props;

        if (!value) return;

        // Последние два символа
        const text = value[value.length - 2].charAt(0).toUpperCase() + value[value.length - 1].charAt(0).toUpperCase();

        const charIndex = text.split('').reduce((acc, item) => acc + item.charCodeAt(0), 0);
        const colourIndex = charIndex % this._colors.length;

        const canvas = this._container;
        const context = canvas.getContext('2d');

        const canvasWidth = canvas.offsetWidth;
        const canvasHeight = canvas.offsetHeight;
        const canvasCssWidth = canvasWidth;
        const canvasCssHeight = canvasHeight;

        // if (window.devicePixelRatio) {
        //     canvas.width = canvasWidth * window.devicePixelRatio;
        //     canvas.height = canvasHeight * window.devicePixelRatio;
        //     canvas.style.width = canvasCssWidth.toString();
        //     canvas.style.height = canvasCssHeight.toString();
        //     context.scale(window.devicePixelRatio, window.devicePixelRatio);
        // }

        context.fillStyle = this._colors[colourIndex];
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.font = '20px/42px Arial';
        context.textAlign = 'center';
        context.fillStyle = '#FFF';
        context.fillText(text, canvasCssWidth / 2, canvasCssHeight / 1.5);
    }
}
