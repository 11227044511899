import moment from 'moment-timezone';
import React from 'react';
import { ProcessStatusEnum } from './../../../enums/process-status.enum';
import { IProcess } from './../../../models/process.model';
import { ProcessUtils } from './../../utils/process.utils';

const { TZ } = process.env;
export class ProcessActiveComponent extends React.Component<{ value: IProcess }, {}> {
    private _interval: number;
    private _period: number = 60 * 1000;

    componentDidMount() {
        this._interval = window.setInterval(() => {
            this.setState({});
        }, this._period);
    }

    componentWillUnmount() {
        window.clearInterval(this._interval);
    }

    render() {
        const { value } = this.props;
        const period = ProcessUtils.parsePeriod(value.period);
        const startTime = moment.tz(value.startTime, TZ);
        const lastPointDate = startTime.clone().add(period.amount * (value.point - 1), period.unit as any);
        const nextUpdateDate = lastPointDate.clone().add(period.amount, period.unit as any);
        const nextUpdateDifference = nextUpdateDate.valueOf() - new Date().getTime();
        const processStatus = ProcessUtils.getProcessStatus(value);

        let icon = null;
        switch (processStatus) {
            case ProcessStatusEnum.VERY_GOOD:
                icon = <i className="material-icons tiny teal-text lighten-2">sentiment_very_satisfied</i>;
                break;
            case ProcessStatusEnum.GOOD:
                icon = <i className="material-icons tiny teal-text darken-4">sentiment_satisfied</i>;
                break;
            case ProcessStatusEnum.BAD:
                icon = <i className="material-icons tiny orange-text">sentiment_dissatisfied</i>;
                break;
            case ProcessStatusEnum.VERY_BAD:
                icon = <i className="material-icons tiny red-text">sentiment_very_dissatisfied</i>;
                break;
            default:
                break;
        }

        return (
            <div className="process-active-component">
                Активный процесс: {ProcessUtils.formatName(value)}
                <br /> последняя&nbsp;точка&nbsp;{lastPointDate.format('DD.MM.YYYY HH:mm:ss')}
                <br />
                {icon}&nbsp;
                {nextUpdateDifference < 0 ? 'обновление должно было состояться' : 'следующее обновление через'}&nbsp;
                {moment.duration(nextUpdateDifference).humanize(nextUpdateDifference < 0)}
            </div>
        );
    }
}
