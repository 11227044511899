import React from 'react';

export class TooltipComponent extends React.PureComponent<{ tooltip: string }, {}> {
    private _container: HTMLSpanElement;
    private _instance: M.Tooltip;

    componentDidMount() {
        this._instance = new M.Tooltip(this._container);
    }

    componentWillUnmount() {
        this._instance.destroy();
    }

    render() {
        return (
            <span
                className="dashed"
                data-position="top"
                data-delay="50"
                data-tooltip={this.props.tooltip}
                ref={ref => (this._container = ref)}
            >
                {this.props.children}
            </span>
        );
    }

    open(): void {
        this._instance.open();
    }

    close(): void {
        this._instance.close();
    }
}
