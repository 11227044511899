import Highcharts, {
    AxisOptions,
    ChartObject,
    GlobalOptions,
    Options
} from 'highcharts';
import React from 'react';

interface IChartProps {
    className?: string;
    type?: string;
    container: string;
    options: Options;
    modules?: any[];
}

export class ChartComponent extends React.Component<IChartProps> {
    static defaultProps: IChartProps = {
        className: ''
    } as IChartProps;

    private _chart: ChartObject;

    componentDidMount() {
        // Extend Highcharts with modules
        if (this.props.modules) {
            this.props.modules.forEach(module => {
                module(Highcharts);
            });
        }
        Highcharts.setOptions({
            time: { timezoneOffset: new Date().getTimezoneOffset() }
        } as GlobalOptions);
        // Set container which the chart should render to.
        this._chart = Highcharts.chart(
            this.props.container,
            this.props.options
        );
    }

    shouldComponentUpdate() {
        return false;
    }

    componentWillReceiveProps(nextProps: IChartProps) {
        if (this._chart && this.props.options !== nextProps.options) {
            this._chart.showLoading('Обновление');

            if (!this._chart.series || !this._chart.series.length) {
                nextProps.options.series.forEach(s => {
                    this._chart.addSeries(s, true);
                });
            } else {
                nextProps.options.series.forEach((s, i) => {
                    this._chart.series[i].setData(s.data as any);
                });
            }
            if (this._chart.yAxis && this._chart.yAxis.length > 1) {
                (this.props.options.yAxis as AxisOptions[]).forEach(
                    (yAxis, i) => {
                        const axis = this._chart.yAxis[i];
                        axis.update(yAxis);
                    }
                );
            }

            this._chart.hideLoading();
        }
    }

    componentWillUnmount() {
        this._chart.destroy();
    }

    render() {
        const { className, container } = this.props;

        return <div className={className} id={container} />;
    }
}
