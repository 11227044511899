import { withFormsy } from 'formsy-react';
import React from 'react';

@withFormsy
export default class CustomInput extends React.Component<any, any> {
    shouldComponentUpdate(nextProps: any) {
        let result = false;
        if (this.props.getValue() !== nextProps.value) {
            result = true;
        }
        return result;
    }

    changeValue(event: React.FormEvent<any>) {
        this.props.setValue(event.currentTarget[this.props.type === 'checkbox' ? 'checked' : 'value']);
        if (typeof this.props.onChange === 'function') {
            const t = setTimeout(() => {
                clearTimeout(t);
                this.props.onChange(this.props.getValue());
            }, 0);
        }
    }

    render() {
        const { className, id, required, readOnly } = this.props;

        return (
            <input
                type={this.props.type || 'text'}
                name={this.props.name}
                id={id}
                onChange={event => this.changeValue(event)}
                value={this.props.getValue()}
                required={required}
                className={className}
                readOnly={readOnly}
            />
        );
    }
}
