import React from 'react';
import { MenuItem } from './menu-item.component';

interface IMenuProps {
    id?: string;
    className: string;
    items: { link: string; name?: string; icon?: string }[];
    currentRoute: string;
    beforeItems?: JSX.Element[];
    afterItems?: JSX.Element[];
}

export class Menu extends React.PureComponent<IMenuProps, {}> {
    private _activeClassName: string = 'active';

    render() {
        const { afterItems, className, currentRoute, id, items, beforeItems } = this.props;

        return (
            <ul id={id} className={className}>
                {beforeItems}
                {items.map((item, index) => (
                    <MenuItem
                        key={index}
                        item={item}
                        className={item.link === currentRoute ? this._activeClassName : ''}
                    />
                ))}
                {afterItems}
            </ul>
        );
    }
}
