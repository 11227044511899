import { PlainRoute } from 'react-router';

export class NavigationUtils {
    isParent(routes: PlainRoute[]): boolean {
        const last1 = routes[routes.length - 1];
        const last2 = routes[routes.length - 2];
        return (last1 as any).hideBackButton || (last2 && last2.indexRoute && last2.indexRoute === last1);
    }
}

export const navigationUtils = new NavigationUtils();
