import React from 'react';

export class NoResult<T> extends React.PureComponent<{ data: IQueryResult<T>; message?: string }, {}> {
    render() {
        if (this.props.data && this.props.data.items && this.props.data.items.length) return null;

        const message = this.props.message || 'Нет данных.';
        return <p className="center-align">{message}</p>;
    }
}
